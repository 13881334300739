import { EditButton, RemoveButton } from '@/src/modules/cart/StyledComponents'
import { removeBoxItemAndSendAnalyticEvent } from '@/src/modules/cart/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { BOX_URL } from '@/src/modules/checkout/strings'
import { useRouter } from 'next/router'
import { TBoxItem } from '@/src/modules/cart/ICart'

const BoxRemoveAndEditButtons = ({
    removeItemFromCart,
    box,
}: {
    removeItemFromCart: Function
    box: TBoxItem
}) => {
    const router = useRouter()
    return (
        <div className={'add-mt'}>
            <RemoveButton
                onClick={() =>
                    removeBoxItemAndSendAnalyticEvent({
                        removeItemFromCart: removeItemFromCart,
                        boxItem: box,
                    })
                }
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </RemoveButton>
            <EditButton
                onClick={async () =>
                    await router.push({
                        pathname: BOX_URL,
                        query: { box_edit_uuid: box.box_uuid, step: 2 },
                    })
                }
            >
                <FontAwesomeIcon icon={faPencil} />
            </EditButton>
        </div>
    )
}

export default BoxRemoveAndEditButtons
