import { FC } from 'react'
import { styled, theme } from '../../../../stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import NavigationMenuDropdownItem from './Navigation/NavigationMenuDropdownItem'
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { signOut } from '@/src/modules/login/FirebaseAuth'

const NavigationMenuRoot = styled(NavigationMenu.Root, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
})
const NavigationMenuContent = styled(NavigationMenu.Content, {
    position: 'relative',
    minWidth: 'max-content',
    zIndex: 2,
})
const NavigationMenuList = styled(NavigationMenu.List, {
    listStyle: 'none',
    padding: 0,
})
const NavigationMenuItem = styled(NavigationMenu.Item, {
    '&.hasChildren&:hover': {
        navDropdownShadow: '',
    },
})
const LabelMenu = styled(NavigationMenu.Trigger, {
    fontFamily: theme.fonts.default,
    borderRadius: '0',
    backgroundColor: 'transparent',
    width: '100%',
    py: 12,
    pr: 22,
    pl: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    position: 'relative',
    color: '$darkest',
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    '&:hover': {
        cursor: 'pointer',
    },
    '&[data-state="open"]': {
        backgroundColor: '$white',
        textDecoration: 'underline',
        textDecorationColor: '$primary',
        textDecorationThickness: '2px',
        textUnderlineOffset: '8px',
        zIndex: 2,
    },
})
const ContainerDiv = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    pt: '30px',
    px: '15px',
    pb: '15px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    background: '$white',
    maxWidth: '100%',
    minWidth: '20em',
    borderRadius: '0 0 3px 3px',
    navDropdownShadow: '',
    '&.lastMenu': {
        left: 'unset',
        right: 0,
        minWidth: '300px',
    },
})
const UserIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    width: 12,
    height: 12,
    ml: '0.5rem',
    mr: '0.8rem',
    '[data-state=open] &': {
        color: '$primary',
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})

const NavigationLoginMenu = styled('div', {
    display: 'flex',
    flexDirection: 'column',
})

const NavigationCorporateMenuItems = styled('div', {
    '&.corporateMenu': {
        order: 1,
        marginBottom: '2rem',
    },
    '&.consumerMenu': {
        order: 2,
    },
})

const NavigationConsumerMenuItems = styled('div', {
    '&.corporateMenu': {
        order: 2,
    },
    '&.consumerMenu': {
        order: 1,
        marginBottom: '2rem',
    },
})

const LoginVsAccountText = styled('span', {
    textAlign: 'left',
    width: 105,
})

const HeaderUserMenu: FC<{ isCorporate: boolean }> = ({ isCorporate }) => {
    const { user } = useUser()

    return (
        <NavigationMenuRoot aria-label={'User'}>
            <NavigationMenuList>
                <NavigationMenuItem
                    onPointerMove={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                    className={'hasChildren'}
                    data-testid={'consumer-login-menu'}
                >
                    <LabelMenu>
                        <UserIcon icon={faUser} />
                        <LoginVsAccountText>
                            {user ? 'MY ACCOUNT' : 'LOG IN'}
                        </LoginVsAccountText>
                    </LabelMenu>
                    <NavigationMenuContent>
                        <ContainerDiv
                            data-testid={'dropdown-content-wrapper'}
                            className={isCorporate ? 'lastMenu' : ''}
                        >
                            <NavigationLoginMenu>
                                <NavigationConsumerMenuItems
                                    className={
                                        isCorporate
                                            ? 'corporateMenu'
                                            : 'consumerMenu'
                                    }
                                >
                                    {user ? (
                                        <>
                                            <NavigationMenuDropdownItem
                                                label={'Dashboard'}
                                                icon_class={'fa-home'}
                                                url={'/account'}
                                                test_id="dt-corp"
                                                is_external_url={false}
                                            />
                                            <NavigationMenuDropdownItem
                                                label={'Received Gifts'}
                                                icon_class={'fa-gift'}
                                                url={'/account/gifts/received'}
                                                test_id="dt-corp"
                                                is_external_url={false}
                                            />
                                            <NavigationMenuDropdownItem
                                                label={'Manage Account'}
                                                icon_class={'fa-user'}
                                                url={'/account/manage-account'}
                                                test_id="dt-corp"
                                                is_external_url={false}
                                            />
                                            <div onClick={signOut}>
                                                <NavigationMenuDropdownItem
                                                    label={'Sign Out'}
                                                    icon_class={
                                                        'fa-right-to-bracket'
                                                    }
                                                    test_id="dt-user-logout"
                                                    is_external_url={false}
                                                    url={'/'}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <NavigationMenuDropdownItem
                                                label={'FOR INDIVIDUALS'}
                                                test_id="dt-consumer-section-header"
                                            />
                                            <NavigationMenuDropdownItem
                                                label={'Log in to PerfectGift'}
                                                icon_class={'fa-gift'}
                                                url={'/login'}
                                                test_id="dt-login"
                                            />
                                        </>
                                    )}
                                </NavigationConsumerMenuItems>
                                <NavigationCorporateMenuItems
                                    className={
                                        isCorporate
                                            ? 'corporateMenu'
                                            : 'consumerMenu'
                                    }
                                >
                                    <NavigationMenuDropdownItem
                                        label={'FOR BUSINESS'}
                                        test_id="dt-corp-section-header"
                                    />
                                    <NavigationMenuDropdownItem
                                        label={'Log in to Corporate Portal'}
                                        icon_class={'fa-briefcase'}
                                        url={
                                            'https://corp.perfectgift.com/login'
                                        }
                                        test_id="dt-corp"
                                        is_external_url={true}
                                    />
                                    <NavigationMenuDropdownItem
                                        label={'Request a corporate account'}
                                        icon_class={'fa-circle-plus'}
                                        url={'/corporate/bulk'}
                                        test_id="dt-create-corporate"
                                    />
                                </NavigationCorporateMenuItems>
                            </NavigationLoginMenu>
                        </ContainerDiv>
                    </NavigationMenuContent>
                </NavigationMenuItem>
            </NavigationMenuList>
        </NavigationMenuRoot>
    )
}

export default HeaderUserMenu
