import { styled, theme } from '@/src/stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import NavigationMenuDropdownItem from '@/src/common/components/elements/Layout/Navigation/NavigationMenuDropdownItem'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'

const NavigationMenuRoot = styled(NavigationMenu.Root, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ml: '5%',
})
const NavigationMenuContent = styled(NavigationMenu.Content, {
    position: 'relative',
    minWidth: 'max-content',
    zIndex: 2,
})
const NavigationMenuList = styled(NavigationMenu.List, {
    listStyle: 'none',
    padding: 0,
})
const NavigationMenuItem = styled(NavigationMenu.Item, {
    '&.hasChildren&:hover': {
        navDropdownShadow: '',
    },
})
const LabelMenu = styled(NavigationMenu.Trigger, {
    fontFamily: theme.fonts.default,
    borderRadius: '0',
    backgroundColor: 'transparent',
    width: '100%',
    p: '12px 10px 12px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    position: 'relative',
    color: '$darkest',
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    '&:hover': {
        cursor: 'pointer',
    },
    '&[data-state="open"]': {
        backgroundColor: '$white',
        textDecoration: 'underline',
        textDecorationColor: '$primary',
        textDecorationThickness: '2px',
        textUnderlineOffset: '8px',
        zIndex: 2,
    },
})

const ChevronIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    width: '12px',
    height: '12px',
    length: 0,
    marginLeft: '.5rem',
    '[data-state=open] &': {
        color: '$primary',
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})

export interface HeaderProps {
    transformX?: number
}

const HeaderHelpMenu = ({ transformX }: HeaderProps) => {
    const ContainerDiv = styled('div', {
        display: 'flex',
        flexDirection: 'column',
        px: '13%',
        py: 20,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        background: '$white',
        maxWidth: '100%',
        minWidth: 'max-content',
        borderRadius: '0 0 3px 3px',
        navDropdownShadow: '',
        transform: transformX ? `translateX(${transformX}%)` : '',
        '@sm': {
            transform: transformX ? `translateX(${transformX - 2.75}%)` : '',
        },
        '&.lastMenu': {
            left: 'unset',
            right: 0,
            minWidth: '300px',
        },
    })

    return (
        <NavigationMenuRoot aria-label={'Help'}>
            <NavigationMenuList>
                <NavigationMenuItem
                    onPointerMove={(event) => event.preventDefault()}
                    onPointerLeave={(event) => event.preventDefault()}
                    className={'hasChildren'}
                    data-testid={'24-7-help-menu'}
                >
                    <LabelMenu>
                        24/7 Help
                        <ChevronIcon icon={faChevronDown} />
                    </LabelMenu>
                    <NavigationMenuContent>
                        <ContainerDiv data-testid={'dropdown-content-wrapper'}>
                            <NavigationMenuDropdownItem
                                label={'Visit our help center'}
                                gradient_words="help center"
                                icon_class={'fa-square-question'}
                                url={
                                    'https://perfectgiftsupport.zendesk.com/hc/en-us'
                                }
                                test_id="dt-help-center"
                            />
                            <NavigationMenuDropdownItem
                                label={'Activate your card'}
                                gradient_words="Activate"
                                icon_class={'fa-credit-card'}
                                url={'/activate'}
                                test_id="dt-activate"
                            />
                            <NavigationMenuDropdownItem
                                label={'Check balance'}
                                gradient_words="balance"
                                icon_class={'fa-receipt'}
                                url={'/check-balance'}
                                test_id="dt-check-balance"
                            />
                            <NavigationMenuDropdownItem
                                label={'Track your order'}
                                gradient_words="Track"
                                icon_class={'fa-truck-fast'}
                                url={'/order-status'}
                                test_id="dt-track-order"
                            />
                        </ContainerDiv>
                    </NavigationMenuContent>
                </NavigationMenuItem>
            </NavigationMenuList>
        </NavigationMenuRoot>
    )
}

export default HeaderHelpMenu
