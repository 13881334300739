import { styled } from '../../../../stitches.config'

export const Text = styled('span', {
    // Reset
    lineHeight: '1',
    margin: '0',
    fontWeight: 400,
    fontVariantNumeric: '$default',
    display: 'inline-block',

    variants: {
        size: {
            '1': {
                fontSize: '$1',
            },
            '2': {
                fontSize: '$2',
            },
            '3': {
                fontSize: '$3',
            },
            '4': {
                fontSize: '$4',
            },
            '5': {
                fontSize: '$5',
                //letterSpacing: '-.015em',
            },
            '6': {
                fontSize: '$6',
                //letterSpacing: '-.016em',
            },
            '7': {
                fontSize: '$7',
                // letterSpacing: '-.031em',
                // textIndent: '-.005em',
            },
            '8': {
                fontSize: '$8',
                // letterSpacing: '-.034em',
                // textIndent: '-.018em',
            },
            '9': {
                fontSize: '$9',
                letterSpacing: '.8px',
                marginBottom: '1rem',
                //textIndent: '-.025em',
            },
        },
        weight: {
            bold: {
                fontWeight: 700,
            },
            inherit: {
                fontWeight: 'inherit',
            },
        },
        variant: {
            default: {
                color: '$darkest',
            },
            handWritten: {
                fontFamily: '$handWritten',
            },
        },
        gradient: {
            true: {
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
        },
    },
    // This makes it tough to override with global or builder styles
    // defaultVariants: {
    //     size: '3',
    //     variant: 'contrast',
    // },
})

export default Text
