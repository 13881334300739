import { EditButton, RemoveButton } from '@/src/modules/cart/StyledComponents'
import { removeCartItem } from '@/src/modules/cart/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { useRouter } from 'next/router'
import { ILineItem } from '@/src/modules/cart/ICart'

const LineItemRemoveAndEditButtons = ({
    removeItemFromCart,
    editCartItem,
    cartLineItem,
    lineItemUuid,
    isPhysical,
    isCardToCard,
    isVirtual,
    mediumType,
    brandMerchant,
    isPgPlus,
}: {
    lineItemUuid: string
    mediumType: string
    brandMerchant: string
    cartLineItem: ILineItem
    editCartItem: Function
    removeItemFromCart: Function
    isPhysical: boolean
    isVirtual: boolean
    isCardToCard: boolean
    isPgPlus: boolean
}) => {
    const router = useRouter()
    return (
        <div className={'add-mt'}>
            <RemoveButton
                onClick={() =>
                    removeCartItem({
                        removeItemFromCart: removeItemFromCart,
                        line_item_uuid: lineItemUuid,
                        cartLineItem: cartLineItem,
                        mediumType: mediumType,
                    })
                }
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </RemoveButton>
            <EditButton
                onClick={() =>
                    editCartItem({
                        router: router,
                        line_item_uuid: lineItemUuid,
                        isPhysical: isPhysical,
                        isVirtual: isVirtual,
                        isCardToCard: isCardToCard,
                        brandSlug: brandMerchant,
                        isPgPlus: isPgPlus,
                    })
                }
            >
                <FontAwesomeIcon icon={faPencil} />
            </EditButton>
        </div>
    )
}

export default LineItemRemoveAndEditButtons
