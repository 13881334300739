import { useEffect, useState } from 'react'
import { styled } from '@/src/stitches.config'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { IConsumerBanners } from '@/src/common/components/elements/Layout/Banners/types'
import { gradientAnimation } from '@/src/common/animations'
import BannerPopover from '@/src/common/components/elements/Layout/Banners/BannerPopover'
import { useRouter } from 'next/router'

const ColorfulContainer = styled('div', {
    width: '100vw',
    backgroundSize: '200% 110%',
    animation: `${gradientAnimation} 8s ease infinite`,
    textAlign: 'center',
    color: '$white',
    py: 5,
    variants: {
        gradientStyle: {
            default: {
                background: `-webkit-linear-gradient(87deg, $primary 18.47%, $secondary 80%)`,
            },
            green: {
                background: `-webkit-linear-gradient(87deg, #206a05 18.47%, #2b8704 80%)`,
            },
            red: {
                background: `-webkit-linear-gradient(87deg, #9c0500 18.47%, #c40901 80%)`,
            },
        },
    },
    defaultVariants: {
        gradientStyle: 'default',
    },
})

const ButtonsContainer = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    ai: 'center',
    jc: 'center',
    px: '5px',
})

const BannersWrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    fontSize: '.9rem',
    minHeight: 45,
    maxWidth: '100%',
    '@sm': {
        width: 900,
        fontSize: '1rem',
    },
    '.active': {
        transform: 'translateX(0)',
    },
})

const BannerContent = styled('div', {
    display: 'flex',
    jc: 'center',
    ai: 'center',
    flex: '0 0 100%',
    transition: 'transform 0.5s ease-in-out',
    gap: 20,
    '& p': {
        color: '$white',
        margin: 0,
    },
})

const TextAndIconWrapper = styled('div', {
    display: 'flex',
    ai: 'center',
    jc: 'center',
    gap: 5,
    color: '$white !important',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
})

const BannerTextSpan = styled('span', {
    '& p': {
        width: '100%',
        margin: 'auto',
    },
})

const BookEnd = styled('svg', {})

const BannerButton = styled(LinkButton, {
    display: 'none',
    backgroundColor: 'unset !important',
    textDecoration: 'underline',
    border: 'unset !important',
    color: '$white !important',
    padding: '0px !important',
    '@sm': {
        display: 'inline-block',
    },
})

const StyledFontAwesomeIcon = styled(FontAwesomeIcon, {
    width: 25,
    cursor: 'pointer',
    zIndex: 2,
})

const ConsumerBanner = ({ banners }: IConsumerBanners) => {
    const router = useRouter()
    const totalBanners: number = banners?.length ?? 0
    const hasMultipleBanners: boolean = totalBanners > 1
    const [activeBannerIndex, setActiveBannerIndex] = useState(0)
    const [canAutoScroll, setCanAutoScroll] = useState<boolean>(true)

    useEffect(() => {
        let intervalId: any

        if (canAutoScroll) {
            intervalId = setInterval(() => {
                setActiveBannerIndex(
                    (prevIndex) => (prevIndex + 1) % totalBanners
                )
            }, 6000)
        }

        return () => clearInterval(intervalId)
    }, [canAutoScroll, totalBanners])

    const setSlide = ({ moveToNextStep }: { moveToNextStep: boolean }) => {
        setCanAutoScroll(false)

        // determine which step to move to
        const newIndex = moveToNextStep
            ? (activeBannerIndex + 1) % totalBanners
            : (activeBannerIndex - 1 + totalBanners) % totalBanners
        setActiveBannerIndex(newIndex)

        setTimeout(() => {
            setCanAutoScroll(true)
        }, 500)
    }

    return (
        <ColorfulContainer>
            <ButtonsContainer>
                {hasMultipleBanners && (
                    <StyledFontAwesomeIcon
                        icon={faChevronLeft}
                        onClick={() => setSlide({ moveToNextStep: false })}
                        style={{ paddingRight: 10 }}
                    />
                )}
                <BannersWrapper>
                    {banners.map((banner, index) => {
                        const {
                            buttonText,
                            buttonUrl,
                            bannerText,
                            promoTerms,
                            icon,
                        } = banner

                        return (
                            <BannerContent
                                key={banner.bannerId}
                                id={banner.bannerId}
                                className={
                                    index === activeBannerIndex ? 'active' : ''
                                }
                                style={{
                                    transform: `translateX(-${
                                        activeBannerIndex * 100
                                    }%)`,
                                }}
                            >
                                <TextAndIconWrapper
                                    onClick={() => {
                                        buttonUrl && router.push(buttonUrl)
                                    }}
                                >
                                    {icon && (
                                        <BookEnd height={16} width={16}>
                                            <image
                                                xlinkHref={icon}
                                                width={'100%'}
                                                height={'100%'}
                                            />
                                        </BookEnd>
                                    )}
                                    <BannerTextSpan
                                        dangerouslySetInnerHTML={{
                                            __html: bannerText,
                                        }}
                                    />
                                    {icon && (
                                        <BookEnd height={16} width={16}>
                                            <image
                                                xlinkHref={icon}
                                                width={'100%'}
                                                height={'100%'}
                                            />
                                        </BookEnd>
                                    )}
                                </TextAndIconWrapper>
                                {buttonText && (
                                    <BannerButton
                                        label={buttonText ?? ''}
                                        url={buttonUrl ?? ''}
                                        variant={'primaryOutline'}
                                        textTransform={'uppercase'}
                                        size={'sm'}
                                        showArrow={false}
                                    />
                                )}
                                {promoTerms && (
                                    <BannerPopover promoTerms={promoTerms} />
                                )}
                            </BannerContent>
                        )
                    })}
                </BannersWrapper>
                {hasMultipleBanners && (
                    <StyledFontAwesomeIcon
                        icon={faChevronRight}
                        onClick={() => setSlide({ moveToNextStep: true })}
                        style={{ paddingLeft: 10 }}
                    />
                )}
            </ButtonsContainer>
        </ColorfulContainer>
    )
}

export default ConsumerBanner
