import { CartProvider } from '@/src/common/contexts/CartContext'
import { FC, useEffect } from 'react'
import ConsumerAndCorporateFooter from './ConsumerAndCorporateFooter'
import CorporateHeader from './CorporateHeader'
import Header from './Header'
import CorpPromoBanner from './Banners/CorpPromoBanner'
import ConsumerBanner from '@/src/common/components/elements/Layout/Banners/ConsumerBanner'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import {
    setAdaChatMetaField,
    setSensitiveAdaChatMetaField,
} from '@/src/common/utilities/setAdaChatMetaFields'
import useGetFirebaseToken from '@/src/common/hooks/useGetFirebaseToken'

interface PagePropsInterface {
    content?: object
    consumerBanner?: any
    corporateBanner?: any
    showFooter?: boolean
    showHeader?: boolean
}

interface GlobalLayout {
    Component: Function // This is a Page, which is a React Functional Component. May want to make this interface be more specific here.
    pageProps: PagePropsInterface
    isCorporate: boolean
}

const GlobalLayout: FC<GlobalLayout> = ({
    Component,
    pageProps,
    isCorporate,
}) => {
    const corporateBannerHtml = pageProps?.corporateBanner?.data?.content
    const consumerBannerData = pageProps?.consumerBanner
    const showFooter = pageProps?.showFooter ?? true
    const showHeader = pageProps?.showHeader ?? true

    const { user, isLoggedIn, isLoaded } = useUser()
    const { data: firebaseToken, refetch: refetchFirebaseToken } =
        useGetFirebaseToken()
    const userPhone: string = user?.phoneNumber ?? ''
    const userEmail: string = user?.email ?? ''
    const clearAdaValue: boolean = isLoaded && !isLoggedIn

    useEffect(() => {
        refetchFirebaseToken().then()
    }, [refetchFirebaseToken, user])

    // if user is logged in, send their firebase uid to the chatbot
    // both setMetaFields and setSensitiveMetaFields are available for setting info in chat
    useEffect(() => {
        setSensitiveAdaChatMetaField({
            firebase_token: !clearAdaValue ? firebaseToken : '',
        })

        // @todo determine if we really want/need to pass phone and email in
        setAdaChatMetaField({
            phone_number: !clearAdaValue ? userPhone : '',
            email: !clearAdaValue ? userEmail : '',
        })
    }, [clearAdaValue, firebaseToken, userEmail, userPhone])

    // determines header content (corp vs consumer)
    const headerContent = isCorporate ? (
        <>
            <CorpPromoBanner
                promoBannerHtml={corporateBannerHtml}
                isCorporate={isCorporate}
            />
            <CorporateHeader />
        </>
    ) : (
        <>
            {consumerBannerData?.length && (
                <ConsumerBanner banners={consumerBannerData} />
            )}
            <Header isCorporate={false} />
        </>
    )

    return (
        <CartProvider>
            {showHeader && <>{headerContent}</>}
            <main>
                <Component {...pageProps} />
            </main>
            {showFooter && (
                <ConsumerAndCorporateFooter isCorporate={isCorporate} />
            )}
        </CartProvider>
    )
}

export default GlobalLayout
