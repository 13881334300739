import { useEffect, useState } from 'react'
import { styled } from '@/src/stitches.config'
import HeaderCtaButtons from '@/src/common/components/elements/Layout/HeaderCtaButtons'
import HeaderNetworkImages from '@/src/common/components/elements/Layout/HeaderNetworkImages'
import HeaderHelpMenu from '@/src/common/components/elements/Layout/HeaderHelpMenu'
import HeaderUserMenu from '@/src/common/components/elements/Layout/HeaderUserMenu'
import LogoLink from '@/src/common/components/elements/Layout/LogoLink'
import NavigationMenuWithHover from '@/src/common/components/elements/Layout/Navigation/NavigationMenuWithHover'
import desktopNavData from '@/public/desktopNavMenuData.json'
import desktopNavCorporateData from '@/public/desktopNavMenuCorporateData.json'
import HeaderCartButton from '@/src/common/components/elements/Layout/HeaderCartButton'
import useGetCartManually from '@/src/common/hooks/useGetCartManually'
import { ICart } from '@/src/modules/cart/ICart'
import { removeLineItemFromCart } from '@/src/common/utilities/removeLineItemFromCart'
import { useCookies } from 'react-cookie'
import { PG_CART_STATE_UUID } from '@/config/cookieNames'
import usePostAddCart from '@/src/common/hooks/usePostAddCart'
import buildGoogleEcommerceItemsFromCart from '@/src/common/utilities/analytics/buildGoogleEcommerceItemsFromCart'
import { showCartEvent } from '@/src/common/utilities/gtag'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'
import { removeBoxItemFromCart } from '@/src/modules/checkout/utilities/removeBoxItemFromCart'
import CartFlyout from '@/src/common/components/elements/Layout/CartFlyout'
import MobileNavDrawer from '@/src/common/components/elements/Layout/Navigation/Mobile/MobileNavDrawer'
import { useCartContext } from '@/src/common/contexts/CartContext'

const HeaderWrapper = styled('header', {
    bc: '$white',
    p: '0',
    color: '$dark',
    fontSize: '3',
    position: 'relative',
})
const HeaderTop = styled('div', {
    display: 'flex',
    fd: 'row',
    width: '1440px',
    maxWidth: '100%',
    margin: 'auto',
    '@md': {
        height: 95,
        maxWidth: '93%',
    },
})
const HeaderTopContentLeft = styled('div', {
    display: 'flex',
    jc: 'space-between',
    ai: 'center',
    mr: 'auto',
    maxWidth: '90%',
})
const HeaderTopContentRight = styled('div', {
    display: 'none',
    '@md': {
        display: 'flex',
        jc: 'right',
    },
    '& button': {
        '& span': {
            ml: '0 !important',
        },
    },
})
const CartButtonContainer = styled('div', {
    display: 'flex',
    ai: 'center',
    p: '1rem',
})
const MobileCartButtonContainer = styled('div', {
    display: 'flex',
    ai: 'center',
    p: '0.5rem',
    '@sm': {
        flex: 1,
        jc: 'flex-end',
    },
    '@md': {
        display: 'none',
    },
})
const HeaderBottom = styled('div', {
    display: 'none',
    '@md': {
        display: 'flex',
        position: 'relative',
        width: '100%',
    },
})

interface IHeaderProps {
    isCorporate: boolean
}

const Header = ({ isCorporate }: IHeaderProps) => {
    const { refetch } = useGetCartManually()
    const { mutate: updateCart } = usePostAddCart()
    const [cartData, setCartData] = useState<ICart | null>(null)
    const [cookies] = useCookies([PG_CART_STATE_UUID])
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const {
        setCartTotalCards,
        setCartTotalLoad,
        cartFlyoutOpen,
        setCartFlyoutOpen,
    } = useCartContext()

    const desktopNavItems = isCorporate
        ? desktopNavCorporateData
        : desktopNavData

    const { lineItemsInBoxes, lineItemsNotInBox } =
        getLineItemsForCart(cartData)
    const cartItemCount: number =
        lineItemsNotInBox.length + lineItemsInBoxes.length

    useEffect(() => {
        const fetchCart = () => {
            refetch().then((response) => {
                // @ts-ignore
                setCartData(response.data)
            })
        }
        fetchCart()
        setShowLoader(false)
    }, [refetch, cookies.pgCartStateUuid]) // Re-fetch the cart when the cart_state_uuid cookie changes

    function removeItemFromCart({
        lineItemUuid,
        boxUuid,
    }: {
        lineItemUuid?: string
        boxUuid?: string
    }) {
        setShowLoader(true)
        let newCartData = null

        if (lineItemUuid) {
            newCartData = removeLineItemFromCart(
                cartData as ICart,
                lineItemUuid
            )
        }
        if (boxUuid) {
            newCartData = removeBoxItemFromCart(cartData as ICart, boxUuid)
        }

        updateCart(newCartData, {
            onSuccess: (data) => {
                setCartData(data as ICart)
                setShowLoader(false)
            },
            onError: (error: any) => {
                console.error('Issue updating cart:', error)
                setShowLoader(false)
            },
        })
    }

    useEffect(() => {
        if (cartData) {
            setCartTotalCards(cartData.resolved_data.items_count)
            setCartTotalLoad(
                cartData.resolved_data.load_value_total
                    ? parseFloat(cartData.resolved_data.load_value_total)
                    : 0
            )
        }
    }, [cartData])

    const fireShowCartEvent = () => {
        if (!cartData) {
            return
        }
        const formattedCartData = buildGoogleEcommerceItemsFromCart(cartData)
        showCartEvent(cartData.resolved_data.order_total, formattedCartData)
    }

    return (
        <HeaderWrapper>
            <HeaderTop>
                <CartFlyout
                    cart={cartData}
                    isOpen={cartFlyoutOpen}
                    closeFlyout={() => {
                        setCartFlyoutOpen(false)
                    }}
                    removeItemFromCart={removeItemFromCart}
                    showLoader={showLoader}
                />
                <HeaderTopContentLeft>
                    <MobileNavDrawer isCorporate={isCorporate} />
                    <LogoLink isCorporate={isCorporate} />
                </HeaderTopContentLeft>
                <HeaderTopContentRight>
                    <HeaderNetworkImages />
                    <HeaderHelpMenu />
                    <HeaderUserMenu isCorporate={isCorporate} />
                    <HeaderCtaButtons isCorporate={isCorporate} />
                    <CartButtonContainer>
                        <HeaderCartButton
                            openCartFlyout={() => {
                                fireShowCartEvent()
                                setCartFlyoutOpen(true)
                            }}
                            itemCount={cartItemCount}
                        />
                    </CartButtonContainer>
                </HeaderTopContentRight>
                <MobileCartButtonContainer>
                    <HeaderCartButton
                        openCartFlyout={() => {
                            fireShowCartEvent()
                            setCartFlyoutOpen(true)
                        }}
                        itemCount={cartItemCount}
                    />
                </MobileCartButtonContainer>
            </HeaderTop>
            <HeaderBottom>
                <NavigationMenuWithHover menuData={desktopNavItems} />
            </HeaderBottom>
        </HeaderWrapper>
    )
}

export default Header
