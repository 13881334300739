import {
    ButtonWrapper,
    CloseButton,
    ImagesWrapper,
    NavigationWrapper,
} from '@/src/common/components/elements/Layout/Navigation/StyledComponents'
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose'
import { consumerMobileNavMenuData } from '@/src/common/components/elements/Layout/Navigation/Mobile/consumerMobileNavMenuData'
import { corporateMobileNavMenuData } from '@/src/common/components/elements/Layout/Navigation/Mobile/corporateMobileNavMenuData'
import LinkButton from '@/src/common/components/marketing/buttons/LinkButton'
import { IMobileProps } from '@/src/common/components/elements/Layout/Navigation/Mobile/types'
import NavigationItem from '@/src/common/components/elements/Layout/Navigation/Mobile/NavigationItem'
import Image from 'next/image'
import { DisplayWhenTypes } from '../types'
import { useUser } from '@/src/modules/auth/context/AuthProvider'

const MobileNavigation = ({
    isCorporate,
    closeMenu,
    menuIsClosing,
    setMenuIsClosing,
}: IMobileProps) => {
    const { user } = useUser()
    const mobileNavItems = isCorporate
        ? corporateMobileNavMenuData
        : consumerMobileNavMenuData
    const button1Text: string = isCorporate
        ? 'Small business order'
        : 'Send a gift'
    const button1Url: string = isCorporate
        ? '/corporate/get-started'
        : '/visa-gift-cards'
    const button2Text: string = isCorporate ? 'For consumer' : 'For business'
    const button2Url: string = isCorporate ? '/' : '/corporate'

    return (
        <NavigationWrapper>
            <CloseButton
                icon={faClose}
                onClick={() => {
                    setMenuIsClosing(true)
                    closeMenu()
                }}
            />
            {mobileNavItems?.map((item) => {
                const showItem =
                    item.displayWhen === DisplayWhenTypes.ALWAYS ||
                    (user && item.displayWhen === DisplayWhenTypes.LOGGED_IN) ||
                    (!user &&
                        item.displayWhen === DisplayWhenTypes.LOGGED_OUT) ||
                    !item.displayWhen

                if (!showItem) {
                    return null
                }
                return (
                    <div key={item.testId}>
                        <NavigationItem
                            item={item}
                            menuIsClosing={menuIsClosing}
                            closeMenu={closeMenu}
                        />
                    </div>
                )
            })}
            <ButtonWrapper>
                <LinkButton
                    label={button1Text}
                    variant={'primary'}
                    url={button1Url}
                    textTransform={'uppercase'}
                    size={'sm'}
                    showArrow={false}
                />
                <LinkButton
                    label={button2Text}
                    variant={'primaryOutline'}
                    url={button2Url}
                    textTransform={'uppercase'}
                    size={'sm'}
                    showArrow={false}
                />
            </ButtonWrapper>
            <ImagesWrapper>
                <Image
                    src={'/images/visa_logo.png'}
                    height={63}
                    width={191}
                    alt={'Visa logo'}
                />
                <Image
                    src={'/images/mc_logo.png'}
                    height={63}
                    width={191}
                    alt={'Mastercard logo'}
                />
            </ImagesWrapper>
        </NavigationWrapper>
    )
}

export default MobileNavigation
