import * as React from 'react'
import { FC } from 'react'
import NextLink from 'next/link'
import Button from '../../marketing/buttons/Button'
import { styled, theme } from '../../../../stitches.config'

const ButtonsWrapper = styled('div', {
    display: 'none',
    '@lg': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
})

const CtaButton = styled(Button, {
    fontFamily: theme.fonts.default,
    fontSize: '.941rem',
    padding: '12px 24px',
    letterSpacing: '.1em',
})

const HeaderCtaButtons: FC<{ isCorporate: boolean }> = ({ isCorporate }) => {
    return (
        <ButtonsWrapper>
            {isCorporate ? (
                <>
                    <NextLink href={'/visa-gift-card-box'} passHref>
                        <CtaButton
                            label={'SMALL BUSINESS ORDER'}
                            variant={'primary'}
                            size={'sm'}
                            data-testid={'corp-request-account'}
                        />
                    </NextLink>
                    <NextLink href={'/'} passHref>
                        <CtaButton
                            label={'FOR CONSUMER'}
                            variant={'primaryOutline'}
                            size={'sm'}
                            data-testid={'corp-for-individuals'}
                        />
                    </NextLink>
                </>
            ) : (
                <>
                    <NextLink href={'/visa-gift-cards'} passHref>
                        <CtaButton
                            label={'SEND A GIFT'}
                            variant={'primary'}
                            size={'sm'}
                            data-testid={'consumer-send-gift'}
                        />
                    </NextLink>
                    <NextLink href={'/corporate'} passHref>
                        <CtaButton
                            label={'FOR BUSINESS'}
                            variant={'primaryOutline'}
                            size={'sm'}
                            data-testid={'consumer-for-business'}
                        />
                    </NextLink>
                </>
            )}
        </ButtonsWrapper>
    )
}

export default HeaderCtaButtons
