import React from 'react'
import { styled } from '../../../../stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/pro-light-svg-icons'
import Text from '../Text/Text'

const CartButton = styled('div', {
    display: 'flex',
    alignItems: 'center',
    jc: 'center',
    height: '40px',
    cursor: 'pointer',
    fontSize: '$4',
    textTransform: 'uppercase',
    width: '50px',
    '@md': {
        width: '100px',
    },
})

const CartIcon = styled(FontAwesomeIcon, {
    color: '$primary',
    fontSize: '1.4rem',
    mr: '0.25rem',
    '@md': {
        fontSize: '$4',
        mr: '0.5rem',
    },
})

const CartButtonText = styled(Text, {
    display: 'none',
    '@md': {
        display: 'inline-block',
        width: 'max-content',
    },
})

const MobileCartButtonText = styled(Text, {
    display: 'inline-block',
    '@md': {
        display: 'none',
    },
})

interface HeaderCartButtonProps {
    openCartFlyout: Function
    itemCount?: number | null
}

const HeaderCartButton = ({
    openCartFlyout,
    itemCount,
}: HeaderCartButtonProps) => {
    const itemCountText = itemCount ? `(${itemCount})` : ''
    return (
        <CartButton
            onClick={() => {
                openCartFlyout()
            }}
        >
            <CartIcon icon={faCartShopping} />
            <MobileCartButtonText>{itemCountText}</MobileCartButtonText>
            <CartButtonText>{`Cart ${itemCountText}`}</CartButtonText>
        </CartButton>
    )
}

export default HeaderCartButton
