import { useEffect, useState } from 'react'
import Image from 'next/image'
import { styled } from '@/src/stitches.config'
import ClipLoader from 'react-spinners/ClipLoader'
import { useRouter } from 'next/router'
import { LoadingWrapper } from '@/src/modules/pg-plus/PGPlusStyledComponents'
import { BasicOverlay } from '@/src/common/components/elements/StyledComponents'
import PGPlusCardOverlay from '@/src/modules/pg-plus/content/PGPlusCardOverlay'

const CardContainer = styled('div', {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: 'auto',
    variants: {
        size: {
            sm: {
                maxWidth: 200,
            },
            md: {
                maxWidth: 300,
            },
            lg: {
                maxWidth: 400,
            },
            'lg-with-xs-mobile': {
                width: '95%',
                maxWidth: 330,
                margin: 'auto',
                '@media (min-width: 426px)': {
                    width: '100%',
                    maxWidth: 400,
                    margin: 'unset',
                },
            },
            'card-for-box': {
                width: '50%',
                maxWidth: 240,
                '.emboss-fields, .date, .dateFinal': {
                    fontSize: '.5rem',
                },
                '.date, .dateFinal': {
                    display: 'none',
                    pb: 0,
                    '@media (min-width: 425px)': {
                        display: 'block',
                        pb: 5,
                    },
                },
            },
        },
        boxShadow: {
            default: {
                boxShadow: 'gray 0px 1px 10px -1px',
            },
            'no-shadow': {
                boxShadow: 'none',
            },
        },
        type: {
            extended: {
                br: 0,
                boxShadow: 'grey 0px 1px 12px -1px',
            },
        },
    },
    '& span': {
        display: 'block',
    },
    '& img': {
        maxWidth: '100%',
        height: 'auto',
        aspectRatio: '3/2',
    },
    '.apply-border': {
        left: '3.5%',
        top: '4%',
        width: '93%',
        height: '92%',
        border: '4px solid white',
        br: '18px !important',
    },
    defaultVariants: {
        size: 'md',
        boxShadow: 'default',
    },
})

const ExtendedImage = styled('div', {
    position: 'absolute',
    clipPath: 'inset(5% 4% 6.5% 4% round 20px)',
    '& span': {
        display: 'block',
    },
    '& img': {
        width: 400,
        height: 'auto',
        aspectRatio: '3/2',
    },
})

const ImgProgress = styled(ClipLoader, {
    position: 'absolute',
    top: '43%',
    left: '44%',
    zIndex: 4,
})

const ImageWrapper = styled('div', {
    position: 'absolute',
    top: 0,
})

const ContentWrapper = styled('div', {
    position: 'absolute',
    width: '100%',
    bottom: '8%',
    left: '12%',
    fontSize: 10,
    '@media (min-width: 420px)': {
        fontSize: 13,
    },
    '& p': {
        color: 'white',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        textShadow: '0px 0px 2px #000',
        m: '0 0 3px',
        p: 0,
        transform: 'scale(1, 1.3)',
    },
    '.date': {
        pb: 0,
        pl: '38%',
        '@media (min-width: 375px)': {
            pb: '2%',
        },
    },
    '.dateFinal': {
        pb: '3.6%',
        pl: '38%',
        '@media (min-width: 375px)': {
            pb: '5.25%',
        },
    },
})

export interface CardProps {
    size: 'sm' | 'md' | 'lg' | 'card-for-box' | 'lg-with-xs-mobile'
    image: string
    isExtended?: boolean
    overlayType?: 'full' | 'partial' | 'pg-plus' | 'none'
    showLoader?: boolean
    name?: string
    message?: string
    showCustomFields?: boolean
    setPlaceholderClicked?: Function | null
    priority?: boolean
    boxShadow?: 'default' | 'no-shadow'
    url?: string
    borderRadius?: number | string
    value?: string
}

const Card = ({
    image,
    boxShadow,
    name,
    message,
    url,
    value = '',
    size = 'lg',
    isExtended = false,
    showLoader = false,
    overlayType = 'full',
    showCustomFields = true,
    setPlaceholderClicked = null,
    priority = false,
    borderRadius = 12,
}: CardProps) => {
    const router = useRouter()
    const uniqueId = Math.floor(Math.random() * 1000000000)
    const [isLoading, setIsLoading] = useState(false)
    const cloudinaryHeight = isExtended ? 260 : 280
    const cloudinaryWidth = isExtended ? 365 : 400
    const fullOverlay = overlayType === 'full'
    const fullOverlayUrl =
        'https://res.cloudinary.com/gift-card-granny/image/upload/v1636734531/GCG/card_overlays/visa-field-headers-numbers.png'
    const partialOverlayUrl =
        'https://res.cloudinary.com/gift-card-granny/image/upload/v1668715722/GCG/card_overlays/Visa_GCG_Mostly-Blank_xyfjtm.png'
    const currentDate = new Date().getFullYear()
    const futureDate = new Date(currentDate + 7, 7, 18).toLocaleDateString(
        'en',
        {
            month: '2-digit',
            year: '2-digit',
        }
    )

    function determineOverlay() {
        if (!overlayType || overlayType === 'none') {
            return undefined
        }

        if (overlayType === 'pg-plus') {
            return (
                <PGPlusCardOverlay
                    value={value}
                    borderRadius={borderRadius}
                    isPgPlus={true}
                />
            )
        }

        const image = fullOverlay ? fullOverlayUrl : partialOverlayUrl
        return (
            <BasicOverlay
                className={isExtended ? 'apply-border' : ''}
                style={{ borderRadius: borderRadius }}
            >
                <ImageWrapper className={'image-wrap-top'}>
                    <Image
                        src={image}
                        height={cloudinaryHeight}
                        width={cloudinaryWidth}
                        alt={'card overlay image'}
                        priority={priority}
                    />
                </ImageWrapper>
                {showCustomFields && (
                    <ContentWrapper className={'emboss-fields'}>
                        <p
                            className={
                                isExtended ? 'date emboss-field' : 'dateFinal'
                            }
                        >
                            {futureDate}
                        </p>
                        <p>{name ?? 'Recipient name'}</p>
                        <p>{message ?? 'Custom message'}</p>
                    </ContentWrapper>
                )}
            </BasicOverlay>
        )
    }

    useEffect(() => {
        setIsLoading(true)
    }, [image])

    if (showLoader) {
        return (
            <CardContainer size={size}>
                <LoadingWrapper style={{ minHeight: 'unset' }}>
                    <ClipLoader color={'gray'} size={30} loading={true} />
                </LoadingWrapper>
            </CardContainer>
        )
    }

    return (
        <CardContainer
            id={`card-${uniqueId}`}
            size={size}
            data-testid={'card'}
            className={'card-image'}
            type={isExtended ? 'extended' : undefined}
            onClick={() => {
                setPlaceholderClicked && setPlaceholderClicked(true)
                url && router.push(url)
            }}
            boxShadow={boxShadow}
            style={{ borderRadius: borderRadius }}
        >
            {isLoading && (
                <ImgProgress
                    id={'loader'}
                    color={'gray'}
                    loading={isLoading}
                    size={30}
                />
            )}
            {determineOverlay()}
            {isExtended && (
                <ExtendedImage>
                    <Image
                        alt="Clipped Plastic Visa Card Image"
                        src={image}
                        height={280}
                        width={400}
                        draggable={false}
                        data-testid={'clipped-image'}
                        priority={priority}
                    />
                </ExtendedImage>
            )}
            <Image
                alt="Plastic Visa Gift Card Image"
                src={image}
                height={282}
                width={400}
                draggable={false}
                data-testid={'plastic-image'}
                onLoadingComplete={() => setIsLoading(false)}
                style={
                    isExtended
                        ? { filter: 'opacity(0.5)' }
                        : { borderRadius: borderRadius }
                }
                priority={priority}
            />
        </CardContainer>
    )
}

export default Card
