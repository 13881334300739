import { styled } from '@/src/stitches.config'

export const StyledForm = styled('form', {
    width: '100%',
    maxWidth: 450,
    '& p': {
        mt: 0,
    },
    '.field-label': {
        fontWeight: 'bold',
        fontSize: '$4',
        mt: 0,
        mb: 15,
    },
    '.brand-field-wrapper': {
        width: 600,
        maxWidth: '100%',
        mb: 30,
    },
    '.form-header': {
        fontWeight: 700,
        mt: 15,
        mb: 20,
        fontSize: 20,
    },
    '.smaller-bottom-margin': {
        mb: 10,
    },
})

export const LoadingOverlay = styled('div', {
    display: 'flex',
    jc: 'center',
    ai: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(248, 248, 248, 0.5)',
    zIndex: 1,
})

export const CheckboxWrapper = styled('div', {
    margin: '1.5rem 0',
})

export const CheckboxLabel = styled('label', {
    lineHeight: '1.35rem',
})

export const TermsLink = styled('a', {
    color: '$primary',
    textDecoration: 'underline',
    '&:hover': {
        textDecoration: 'none',
    },
})
