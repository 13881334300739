import {
    SectionLabel,
    SubSectionWrapper,
} from '@/src/common/components/elements/Layout/Navigation/StyledComponents'
import { styled, theme } from '@/src/stitches.config'
import NavigationItem from '@/src/common/components/elements/Layout/Navigation/Mobile/NavigationItem'
import { ISubSectionProps } from '@/src/common/components/elements/Layout/Navigation/Mobile/types'

const ClickableNavigationItemWrap = styled('div', {})

const NavigationSubSection = ({ section, closeMenu }: ISubSectionProps) => {
    return (
        <SubSectionWrapper>
            {section?.label && <SectionLabel>{section?.label}</SectionLabel>}
            {section?.children?.map((item) => {
                const subItemStyle = {
                    fontSize: '.9rem',
                    margin: '10px 12.5px',
                    fontWeight: 'bold',
                    color: `${theme.colors.dark}`,
                    paddingLeft: item?.icon ? 0 : 30,
                }
                return (
                    <>
                    {item.clickAction ? (
                        <ClickableNavigationItemWrap
                            onClick={item.clickAction}
                        >
                            <NavigationItem
                                item={item}
                                key={item.testId}
                                style={subItemStyle}
                                closeMenu={closeMenu}
                            />
                        </ClickableNavigationItemWrap>
                     ) : ( 
                        <NavigationItem
                            item={item}
                            key={item.testId}
                            style={subItemStyle}
                            closeMenu={closeMenu}
                        />
                    )}
                    </>
                )
            })}
        </SubSectionWrapper>
    )
}
export default NavigationSubSection
