import Link from 'next/link'
import Image from 'next/legacy/image'
import { styled } from '@/src/stitches.config'
import NavItemInterface from './NavItemInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    gradientWordsStyles,
    replaceGradientWords,
} from '@/src/common/utilities/replaceGradientWords'
import determineFAIcon from '@/src/common/components/elements/Layout/Navigation/determineFAIcon'

interface INavigationMenuDropdownItem extends NavItemInterface {
    image?: string
    gradient_words?: string
    icon_class?:
        | 'fa-briefcase'
        | 'fa-circle-plus'
        | 'fa-right-to-bracket'
        | 'fa-store'
        | 'fa-user-plus'
        | 'fa-gift'
        | 'fa-home'
        | 'fa-gear'
        | 'fa-phone'
        | 'fa-comment-dots'
        | 'fa-comments'
        | 'fa-envelope'
        | 'fa-square-question'
        | 'fa-credit-card'
        | 'fa-truck-fast'
        | 'fa-receipt'
        | 'fa-user'
    is_external_url?: boolean
    openChatBox?: boolean
}

const ImageLink = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    py: '8px',
    pl: '$2',
    br: '$3',
    '.gradient-words': gradientWordsStyles.normal,
    '&:hover': {
        bc: '$lightest',
        '.gradient-words': gradientWordsStyles.hover,
    },
})

const ImageLinkImageWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
})

const ImageLinkImage = styled(Image, {
    objectFit: 'contain',
    borderRadius: '3px',
})

const IconLinkWrapper = styled('div', {
    width: '3em',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        color: '$secondary',
        display: 'inline-block',
    },
})

const IconLink = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    height: '4em',
    br: '$3',
    '.gradient-words': gradientWordsStyles.normal,
    '&:hover': {
        bc: '$lightest',
        '.gradient-words': gradientWordsStyles.hover,
    },
})

const Icon = styled(FontAwesomeIcon, {
    '[data-state=open] &': {
        color: '$secondary',
        flexGrow: 1,
    },
    '@media (prefers-reduced-motion: no-preference)': {
        transition: 'transform 250ms ease',
    },
})

const NavLinkWrapperOuter = styled('div', {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    opacity: 1,
    color: '$darkest',
})
const NavLinkWrapperInner = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    pl: '1.1em',
    fontWeight: 700,
    opacity: 1,
    color: '$darkest',
})

const NavLink = styled('div', {
    fontSize: '1em',
    fontWeight: 700,
    opacity: 1,
    color: '$darkest',
    cursor: 'pointer',
    pr: 10,
    '&.view-all-link': {
        py: '25px',
        color: '$secondary',
        '&:hover': {
            color: '$secondaryDark',
        },
    },
    '&.simple-link': {
        py: '5px',
        width: '100%',
        '&:hover': {
            color: '$secondaryDark',
        },
    },
    '&:hover': {
        bc: 'transparent',
    },
})

const SubLabel = styled('div', {
    fontSize: '0.83em',
    fontWeight: 300,
    color: '$dark',
    mt: '5px',
})

const SectionHeader = styled('div', {
    color: '$mediumGray',
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: 300,
    my: 15,
    '&:first-of-type': {
        mt: 'inherit',
    },
})

interface INavLinkWithGradientText {
    linkText: string
    gradientWords: string | undefined
}

const NavLinkWithGradientText = ({
    linkText,
    gradientWords,
}: INavLinkWithGradientText) => {
    if (!gradientWords || linkText.indexOf(gradientWords) < 0) {
        return <NavLink>{linkText}</NavLink>
    }
    return <NavLink>{replaceGradientWords(linkText, gradientWords)}</NavLink>
}

interface ConditionalWrapperProps {
    condition: boolean
    defaultLink: Function
    nextLink: Function
    children: any
}

// @ts-ignore
const ConditionalWrapper = ({
    condition,
    defaultLink,
    nextLink,
    children,
}: ConditionalWrapperProps) =>
    condition ? defaultLink(children) : nextLink(children)

export const NavigationMenuDropdownItem = ({
    label,
    sub_label,
    url,
    image,
    gradient_words,
    test_id,
    is_view_all,
    icon_class,
    is_external_url = false,
    openChatBox = false,
}: INavigationMenuDropdownItem) => {
    if (!url) {
        return <SectionHeader>{label}</SectionHeader>
    }

    let linkContent = (
        <NavLinkWrapperOuter>
            <NavLinkWrapperInner>
                <NavLink
                    className={is_view_all ? 'view-all-link' : 'simple-link'}
                    data-testid={'nav-link'}
                >
                    {label}
                </NavLink>
                <SubLabel>{sub_label}</SubLabel>
            </NavLinkWrapperInner>
        </NavLinkWrapperOuter>
    )

    if (image) {
        linkContent = (
            <ImageLink>
                <ImageLinkImageWrapper>
                    <ImageLinkImage
                        src={image}
                        alt={label}
                        width={100}
                        height={63}
                        data-testid={'image_link_image'}
                    />
                </ImageLinkImageWrapper>
                <NavLinkWrapperOuter>
                    <NavLinkWrapperInner>
                        {gradient_words ? (
                            <NavLinkWithGradientText
                                linkText={label}
                                gradientWords={gradient_words}
                            />
                        ) : (
                            <NavLink>{label}</NavLink>
                        )}
                        <SubLabel>{sub_label}</SubLabel>
                    </NavLinkWrapperInner>
                </NavLinkWrapperOuter>
            </ImageLink>
        )
    }

    if (icon_class) {
        const iconClass = determineFAIcon(icon_class)
        linkContent = (
            <IconLink
                onClick={(e) => {
                    if (openChatBox) {
                        e.preventDefault()
                        try {
                            // @ts-ignore
                            window.adaEmbed.toggle()
                        } catch {
                            console.log('Unable to open ADA chat window')
                        }
                    }
                }}
            >
                <IconLinkWrapper>
                    {iconClass && (
                        <Icon
                            size={'2x'}
                            icon={iconClass}
                            data-testid={'icon_link_icon'}
                        />
                    )}
                </IconLinkWrapper>
                <NavLinkWrapperOuter>
                    <NavLinkWrapperInner>
                        {gradient_words ? (
                            <NavLinkWithGradientText
                                linkText={label}
                                gradientWords={gradient_words}
                            />
                        ) : (
                            <NavLink>{label}</NavLink>
                        )}
                        <SubLabel>{sub_label}</SubLabel>
                    </NavLinkWrapperInner>
                </NavLinkWrapperOuter>
            </IconLink>
        )
    }

    if (openChatBox) {
        return linkContent
    }

    return (
        <ConditionalWrapper
            condition={is_external_url}
            defaultLink={(children: any) => {
                return (
                    <a href={url} key={url} data-testid={test_id ?? ''}>
                        {children}
                    </a>
                )
            }}
            nextLink={(children: any) => (
                <Link
                    href={url}
                    key={url}
                    data-testid={test_id ?? ''}
                    passHref
                    legacyBehavior
                >
                    {children}
                </Link>
            )}
        >
            {linkContent}
        </ConditionalWrapper>
    )
}

export default NavigationMenuDropdownItem
