import axios from 'axios'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

export const xhrClient = axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

// @deprecated This has been superseded by pgCorpApiClient.ts.
export const pgApiClient = axios.create({
    baseURL: publicRuntimeConfig.pgApiClientBaseUrl,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
})

// Used by the Next server-side to verify recaptcha responses.
export const googleRecaptchaClient = axios.create({
    baseURL: 'https://www.google.com',
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    params: {
        secret: process.env.RECAPTCHA_SECRET_KEY,
    },
})

export const mailchimpApiClient = axios.create({
    baseURL: 'https://us10.api.mailchimp.com/3.0',
    headers: {
        Authorization: `apikey ${process.env.MAILCHIMP_API_KEY}`,
        'Content-Type': 'application/json',
    },
})

export const hubspotFormsApiV3Client = axios.create({
    baseURL: publicRuntimeConfig.hubspotFormsApiV3ClientBaseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
})
