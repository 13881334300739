import * as React from 'react'
import Image from 'next/image'
import NextLink from 'next/link'

const LogoLink: React.FC<{
    isCorporate: boolean
    minWidth?: string | number
}> = ({ isCorporate, minWidth = '200px' }) => {
    const imageCss = { maxWidth: '95%', height: 'auto', minWidth: minWidth }
    return isCorporate ? (
        <NextLink href={'/corporate'} style={{ display: 'flex' }}>
            <Image
                src="/logo-horizontal-corporate.svg"
                alt="PerfectGift Corporate logo"
                height={93}
                width={292}
                style={imageCss}
                priority
            />
        </NextLink>
    ) : (
        <NextLink href={'/'} style={{ display: 'flex' }}>
            <Image
                src="/logo-horizontal.svg"
                alt="PerfectGift logo"
                height={73}
                width={300}
                style={imageCss}
                priority
            />
        </NextLink>
    )
}

export default LogoLink
